<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading>
            <h2 class="h1 font-weight-light text_google">
              <v-icon large left>mdi-file-send</v-icon
              >การย้ายสายงานบริหารสถานศึกษา  ขอย้ายกรณีเพื่อประโยชน์ของทางราชการ   ประเภท 1 การย้ายเพื่อพัฒนาคุณภาพการศึกษา
            </h2>
          </template>
          <v-card>
            <v-stepper v-model="steps" vertical>
              <v-stepper-step :complete="steps > 1" step="1">
                <div>
                     ข้อมูลเบื้องต้น รหัสรายการอ้างอิง {{ manage_id_ref }} 
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>
             
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-alert type="warning" outlined>
                        <div>
                         <h3>ขอย้ายกรณีเพื่อประโยชน์ของทางราชการ   ประเภท 1 การย้ายเพื่อพัฒนาคุณภาพการศึกษา</h3> 
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-alert
                        border="left"
                        colored-border
                        color="green darken-1"
                        elevation="2"
                        type="info"
                      >
                        <h2>
                          ชื่อ-นามสกุล : {{ user.title_s }}{{ user.frist_name }}
                          {{ user.last_name }}
                        </h2>
                        <h3>รหัสบัตรประชาชน : {{ user.id_card }}</h3>
                        <h3>เลขที่ตำแหน่ง : {{ user.id_position }}</h3>
                        <h3>วิทยฐานะ : {{ user.rang_name }}</h3>
                        <h3>สังกัด (สถานศึกษา) : {{ user.college_name }}</h3>
                        <h3>
                          รับเงินเดือนอันดับ : {{ user.rang_name }} ({{
                            user.rang_level
                          }}) ขั้น
                        </h3>
                        <h3>เงินเดือน : {{ user.salary_s }} บาท</h3>
                        <h3>
                          เกิดวันที่ : {{ user.brith_day }} เดือน
                          {{ user.brith_month }} พ.ศ.
                          {{ user.brith_year }}
                        </h3>
                        <h3>ปัจจุบันอายุ {{ get_Age }}</h3>
                        <hr />
                        <h3>
                          วันที่เริ่มบรรจุเข้ารับราชการ :
                          {{ date_appoin_ch || "-" }}
                        </h3>
                        <h3>
                          ระยะเวลาตั้งแต่บรรจุเข้ารับราชการ : {{ get_gov_Age }}
                        </h3>
                        <h3>สถานะภาพ : {{ marital_status }}</h3>
                      </v-alert>
                    </v-col>

                    <v-col cols="12" sm="6" align="center">
                      <v-alert
                        border="left"
                        colored-border
                        color="green darken-1"
                        elevation="2"
                        type="info"
                      >
                        <h2>
                          ปีงบประมาณ : {{ periods.period_year}}
                        </h2>
                        <h3>เขียนที่ : {{ user.college_name }}</h3>
                        <h3>วันที่ยื่นคำร้อง : {{ date_today }}</h3>
                        <h3>{{ get_gov_Age_year }}</h3>

                        <hr />
                        <div v-if="user.date_app_now === ''">
                          <v-alert prominent type="error">
                            <v-row>
                              <v-col class="grow">
                                <h3>
                                  ให้งานบุคลากร สถานศึกษาของท่าน
                                  ดำเนินการเกี่ยวกับวันที่ปฏิบัติงาน ณ
                                  สถานศึกษาปัจจุบันให้เรียบร้อย
                                </h3>
                              </v-col>
                            </v-row>
                          </v-alert>
                        </div>
                        <div v-else>
                          <h3>
                            วันที่เริ่มปฏิบัติหน้าที่ในสถานศึกษาปัจจุบัน :
                            {{
                              user.date_app_now
                                | moment("add", "543 years")
                                | moment("D MMMM YYYY")
                            }}
                          </h3>
                          <h2>
                            ปฏิบัติหน้าที่เป็นเวลา : {{ get_Appoint_Age }}
                          </h2>

                          {{ localTime }}
                        </div>
                      </v-alert>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-alert
                        border="left"
                        colored-border
                        type="error"
                        elevation="2"
                        v-if="user.tel_p === ''"
                      >
                        <h2 class="red--text">
                          กรุณาปรับปรุงข้อมูลติดต่อ เบอร์โทร E-mail
                          ยังหน้าข้อมูลผู้ใช้งานระบบให้เรียบร้อย
                          <v-btn
                            color="red"
                            large
                            rounded
                            dark
                            to="/UserProfile"
                          >
                            <v-icon>mdi-account-circle</v-icon>
                            ข้อมูลผู้ใช้งานระบบ</v-btn
                          >
                        </h2>
                      </v-alert>
                      <v-alert
                        border="left"
                        colored-border
                        type="error"
                        elevation="2"
                        v-if="personnel_work_history_check.count_work === '0'"
                      >
                        <h2 class="red--text">
                          กรุณา บันทึกประวัติการรับราชการให้เรียบร้อย
                          มีผลต่อการพิจารณา
                          <v-btn
                            color="red"
                            large
                            rounded
                            dark
                            to="/personnel_work_history"
                          >
                            <v-icon>mdi-account-circle</v-icon>
                            ประวัติการรับราชการ</v-btn
                          >
                        </h2>
                      </v-alert>
                      <v-alert
                        border="left"
                        colored-border
                        color="green darken-1"
                        elevation="2"
                        type="info"
                      >
                        <v-card
                          v-if="personnel_education_check.count_ed === '0'"
                        >
                          <v-alert prominent type="error">
                            <v-row align="center">
                              <v-col class="grow">
                                <h2>
                                  ให้ดำเนินการบันทึกข้อมูลคุณวุฒิการศึกษาของท่านเรียบร้อย
                                </h2>
                              </v-col>
                              <v-col class="shrink">
                                <v-btn
                                  large
                                  rounded
                                  dark
                                  to="/personnel_education"
                                >
                                  <v-icon>mdi-school</v-icon>
                                  วุฒิการศึกษา</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-card>
                        <v-card v-else>
                          <v-card-title class="mt-8">
                            <v-avatar size="56">
                              <v-icon large left>mdi-cast-education</v-icon>
                            </v-avatar>
                            <p class="ml-3">ประวัติการศึกษา</p>
                          </v-card-title>

                          <v-card-text>
                            <v-timeline align-top dense>
                              <v-timeline-item
                                v-for="item in personnel_educations"
                                :key="item.id_red"
                                small
                              >
                                <div>
                                  <div class="font-weight-normal">
                                    {{ item.education_level }} คณะวิชา :
                                    {{ item.faculty_name }} สาขาวิชา :
                                    {{ item.branch_name }} สำเร็จปีการศึกษา :
                                    {{ item.year_finish }}
                                  </div>
                                </div>
                              </v-timeline-item>
                            </v-timeline>
                          </v-card-text>
                        </v-card>
                      </v-alert>
                    </v-col>                 
                  </v-row>
                </v-card>
                <v-btn
                  @click="addtransference_manageSubmit((steps = 1))"
                  color="primary"
                >
                  ดำเนินการ
                </v-btn>              
              </v-stepper-content>

              <v-stepper-step :complete="steps > 2" step="2">
                
                <div>
                  ความรู้ความสามารถในการพัฒนาสถานศึกษา
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-card>
                  <v-textarea
                    v-model="transference_manages.manage_knowledge"
                    outlined
                  ></v-textarea>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 2))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=2)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 3" step="3">
                
                <div>
                  ประสบการณ์
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-card>
                  <v-textarea
                    v-model="transference_manages.manage_experience"
                    outlined
                  ></v-textarea>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 3))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=1)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 4" step="4">
                

                <div>
                  ข้อมูลคู่สมรส
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>
              </v-stepper-step>
              <v-stepper-content step="4">
                <v-card>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        v-model="transference_manages.manage_spouse"
                        label="คู่สมรสชื่อ : "
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4"
                      ><v-text-field
                        outlined
                        v-model="transference_manages.manage_spouse_occupation"
                        label="คู่สมรสประกอบอาชีพ : "
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        v-model="transference_manages.manage_spouse_location"
                        label="สถานที่ประกอบอาชีพของคู่สมรส : "
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        outlined
                        v-model="transference_manages.manage_domicile"
                        :items="provices_sh"
                        item-text="province_name"
                        item-value="province_ID"
                        label="ภูมิลำเนาของข้าพเจ้า จังหวัด : "
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        outlined
                        v-model="transference_manages.manage_spouse_domicile"
                        :items="provices_sh"
                        item-text="province_name"
                        item-value="province_ID"
                        label="ภูมิลำเนาของคู่สมรส จังหวัด : "
                      ></v-autocomplete
                    ></v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 4))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=1)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 5" step="5">
                
                <div>
                  ข้อมูลที่อยู่ปัจจุบัน
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>

              </v-stepper-step>
              <v-stepper-content step="5">
                <v-card class="pa-2">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea
                        rows="2"
                        outlined
                        v-model="transference_manages.manage_address_now"
                        label="บ้านเลขที่ หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด รหัสไปรษณีย์: "
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 5))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=3)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 6" step="6">
                
                <div>
                  ข้อมูลที่อยู่เมื่อได้ย้ายแล้ว
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>
              </v-stepper-step>
              <v-stepper-content step="6">
                <v-card>
                  <v-textarea
                    rows="2"
                    outlined
                    v-model="transference_manages.manage_after_move"
                    label="บ้านเลขที่ หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด รหัสไปรษณีย์: "
                  ></v-textarea>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 6))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=4)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 7" step="7">
                
                <div>
                  ข้อมูลติดต่อ
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>

              </v-stepper-step>
              <v-stepper-content step="7">
                <v-card class="pa-2">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-alert
                        border="left"
                        colored-border
                        type="error"
                        elevation="2"
                        v-if="user.tel_p === ''"
                      >
                        กรุณาปรับปรุงข้อมูลยังหน้าข้อมูลผู้ใช้งานระบบ
                      </v-alert>

                      <v-alert
                        border="left"
                        colored-border
                        type="info"
                        elevation="2"
                        v-else
                      >
                        โทรศัพท์ที่สามารถติดต่อได้โดยตรง :
                        {{ user.tel_p }} E-mail : {{ user.e_mail }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 7))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=5)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 8" step="8">
                
                <div>
                  ประวัติการรับราชการ
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>

              </v-stepper-step>
              <v-stepper-content step="8">
                <v-card>
                  <v-data-table
                    :headers="header_work"
                    :items="personnel_work_history"
                    class="elevation-1"
                  >
                    <template v-slot:item="{ index, item }">
                      <tr>
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">
                          {{
                            item.date_begin
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </td>
                        <td class="text-center">{{ item.position_s }}</td>
                        <td class="text-center">
                          {{ item.college_name_work }}
                        </td>
                        <td class="text-center"></td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 8))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=6)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 9" step="9">
                

                <div>
                  อยู่ระหว่างลาศึกษาต่อเต็มเวลาหรือไม่
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>

              </v-stepper-step>
              <v-stepper-content step="9">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-row>
                        <v-alert
                          class="mx-auto justify-center pa-2 ma-2"
                          border="bottom"
                          colored-border
                          type="warning"
                          elevation="2"
                        >
                          <v-radio-group
                            row
                            v-model="transference_manages.manage_on_study_leave"
                          >
                            <v-radio value="no_leave">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text">ไม่อยู่</strong>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="leave">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text">อยู่</strong>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-alert>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-card class="pa-2 ma-2">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="
                                transference_manages.manage_study_leave_location
                              "
                              :disabled="
                                transference_manages.manage_on_study_leave ===
                                  'no_leave'
                              "
                              outlined
                              label="อยู่ระหว่างลาศึกษาต่อที่ :"
                              prepend-icon="mdi-office-building-marker"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="
                                transference_manages.manage_study_leave_level
                              "
                              :disabled="
                                transference_manages.manage_on_study_leave ===
                                  'no_leave'
                              "
                              outlined
                              label="เคยลาศึกษาต่อ (ครั้งล่าสุด) ระดับ :"
                              prepend-icon="mdi-office-building-marker"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-autocomplete
                              v-model="
                                transference_manages.manage_study_leave_college
                              "
                              outlined
                              :items="colleges"
                              item-text="college_name"
                              item-value="college_code"
                              label="สถานศึกษา"
                              prepend-icon="mdi-office-building-marker"
                              :disabled="
                                transference_manages.manage_on_study_leave ===
                                  'no_leave'
                              "
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-dialog
                              ref="dialog_1"
                              v-model="modal_1"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  v-model="
                                    transference_manages.manage_study_leave_start
                                  "
                                  label="ตั้งแต่วันที่ :"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="
                                    transference_manages.manage_on_study_leave ===
                                      'no_leave'
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  transference_manages.manage_study_leave_start
                                "
                                scrollable
                                locale="th"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal_1 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog_1.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-dialog
                              ref="dialog_2"
                              v-model="modal_2"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  v-model="
                                    transference_manages.manage_study_leave_end
                                  "
                                  label="ถึงวันที่ :"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="
                                    transference_manages.manage_on_study_leave ===
                                      'no_leave'
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  transference_manages.manage_study_leave_end
                                "
                                scrollable
                                locale="th"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal_2 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog_2.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 9))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=7)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 10" step="10">
               

                <div>
                  ข้อมูลอื่น ๆ
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>

              </v-stepper-step>
              <v-stepper-content step="10">
                <v-card>
                  <v-textarea
                    v-model="transference_manages.manage_performance"
                    outlined
                    label="ผลการปฏิบัติงาน (ระบุ)"
                  ></v-textarea>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 10))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=8)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 11" step="11">
                

                <div>
                  การรักษาวินัยและจรรยาบรรณ
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>


              </v-stepper-step>
              <v-stepper-content step="11">
                <v-card>
                  <v-alert
                    class="mx-auto justify-center pa-2 ma-2"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                  >
                    <h3>เคยถูกลงโทษทางวินัยหรือไม่</h3>
                    <v-radio-group
                      v-model="transference_manages.manage_disciplinary"
                    >
                      <v-radio value="no_discip">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text"
                              >ไม่เคยถูกลงโทษทางวินัย</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="discip">
                        <template v-slot:label>
                          <div>
                            <strong class="warning--text"
                              >เคยถูกลงโทษทางวินัย</strong
                            >
                          </div>
                          <v-checkbox
                            v-model="transference_manages.manage_disciplinary_1"
                            label="ภาคทัณ์"
                            value="1"
                            :disabled="
                              transference_manages.manage_disciplinary ===
                                'no_discip'
                            "
                          ></v-checkbox>
                          <v-checkbox
                            v-model="transference_manages.manage_disciplinary_2"
                            label="ตัดเงินเดือน"
                            value="1"
                            :disabled="
                              transference_manages.manage_disciplinary ===
                                'no_discip'
                            "
                          ></v-checkbox>
                          <v-checkbox
                            v-model="transference_manages.manage_disciplinary_3"
                            label="ลดเงินเดือน"
                            value="1"
                            :disabled="
                              transference_manages.manage_disciplinary ===
                                'no_discip'
                            "
                          ></v-checkbox>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-alert>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 11))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=9)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 12" step="12">
                

                <div>
                  เคยถูกพิจารณาเกี่ยวกับการประกอบวิชาชีพครู/ผู้บริหารสถานศึกษาหรือไม่
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>



              </v-stepper-step>
              <v-stepper-content step="12">
                <v-card>
                  <v-radio-group v-model="transference_manages.manage_license">
                    <v-radio value="no_license">
                      <template v-slot:label>
                        <div>
                          <strong class="primary--text">ไม่เคย</strong>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="license">
                      <template v-slot:label>
                        <div>
                          <strong class="warning--text">เคย</strong>
                        </div>
                        <v-checkbox
                          v-model="transference_manages.manage_license_1"
                          label="ตักเตือน"
                          value="1"
                          :disabled="
                            transference_manages.manage_license === 'no_license'
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="transference_manages.manage_license_2"
                          label="ภาคทัณฑ์"
                          value="1"
                          :disabled="
                            transference_manages.manage_license === 'no_license'
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="transference_manages.manage_license_3"
                          label="พักใช้ใบอนุญาต"
                          value="1"
                          :disabled="
                            transference_manages.manage_license === 'no_license'
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="transference_manages.manage_license_4"
                          label="เพิกถอนใบอนุญาต"
                          value="1"
                          :disabled="
                            transference_manages.manage_license === 'no_license'
                          "
                        ></v-checkbox>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 12))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=10)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 13" step="13">
                

                <div>
                  ปริมาณงานหน่วยงานการศึกษาปัจจุบัน
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>


              </v-stepper-step>
              <v-stepper-content step="13">
                <v-card>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="transference_manages.manage_personnel"
                        outlined
                        label="จำนวนบุคลากร คน:"
                        prepend-icon="mdi-office-building-marker"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6"
                      ><v-text-field
                        v-model="transference_manages.manage_classroom"
                        outlined
                        label="จำนวนห้องเรียน ห้อง:"
                        prepend-icon="mdi-office-building-marker"
                        type="number"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6"
                      ><v-text-field
                        v-model="transference_manages.manage_std_all"
                        outlined
                        label="จำนวนนักเรียนรวม คน:"
                        prepend-icon="mdi-office-building-marker"
                        type="number"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6"
                      ><v-text-field
                        v-model="transference_manages.manage_std_vc"
                        outlined
                        label="ปวช. จำนวน คน:"
                        prepend-icon="mdi-office-building-marker"
                        type="number"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6"
                      ><v-text-field
                        v-model="transference_manages.manage_std_hvc"
                        outlined
                        label="ปวส. จำนวน คน:"
                        prepend-icon="mdi-office-building-marker"
                        type="number"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6"
                      ><v-text-field
                        v-model="transference_manages.manage_std_sc"
                        outlined
                        label="ระยะสั้น จำนวน คน:"
                        prepend-icon="mdi-office-building-marker"
                        type="number"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 13))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=11)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 14" step="14">
                

                <div>
                  อยู่ระหว่างช่วยปฏิบัติราชการหรือไม่
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>



              </v-stepper-step>
              <v-stepper-content step="14">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="transference_manages.manage_service_status"
                        >
                          <v-radio value="not_service">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ไม่อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="gov_service">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text"
                                  >ช่วยปฏิบัติราชการ</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-card class="pa-2 ma-2">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            v-model="transference_manages.manage_service_status"
                            outlined
                            :items="colleges"
                            item-text="college_name"
                            item-value="college_code"
                            label="ช่วยปฏิบัติราชการที่ :"
                            prepend-icon="mdi-office-building-marker"
                            :disabled="
                              transference_manages.manage_service_status ===
                                'not_service'
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-dialog
                            ref="dialog_3"
                            v-model="modal_3"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="
                                  transference_manages.manage_service_datetime
                                "
                                label="ตั้งแต่วันที่ :"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="
                                  transference_manages.manage_service_status ===
                                    'not_service'
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="
                                transference_manages.manage_service_datetime
                              "
                              scrollable
                              locale="th"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal_3 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog_3.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 14))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=12)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>
<!-- 
              <v-stepper-step :complete="steps > 15" step="15">   
                <div>
                  ขอย้ายกรณี
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>
              </v-stepper-step>
              <v-stepper-content step="15">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-row>
                        <v-alert
                          class="mx-auto justify-center pa-2 ma-2"
                          border="bottom"
                          colored-border
                          type="warning"
                          elevation="2"
                        >
                          <v-radio-group
                            row
                            v-model="transference_manages.manage_case_move"
                          >
                            <v-radio value="normal">
                              <template v-slot:label>
                                <div>
                                  <strong
                                    class="primary--text"
                                    v-on:click="clearCasemove()"
                                    >ปกติ</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="special">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text">พิเศษ</strong>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-alert>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-card class="pa-2 ma-2">
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-card
                              class="pa-2 ma-2"
                              v-if="
                                transference_manages.manage_case_move ===
                                  'normal'
                              "
                            >
                              <v-card-title>
                                <div
                                  class="font-weight-light v-size--x-large"
                                  color="pink darken-2"
                                >
                                  <v-icon large left
                                    >mdi-account-details</v-icon
                                  >
                                  เหตุผลการขอย้าย กรณีปกติ
                                </div>
                              </v-card-title>

                              <v-col cols="12" sm="12">
                                <v-card class="pa-2">
                                  <v-card-text>
                                    <v-row align="center">
                                      <v-card-title>
                                        <div
                                          class="font-weight-light v-size--x-large"
                                        >
                                          <v-icon></v-icon>
                                          ย้ายเพื่ออยู่ร่วมคู่สมรส
                                        </div>
                                      </v-card-title>

                                      <v-checkbox
                                        v-model="
                                          transference_manages.manage_reason_1
                                        "
                                        value="1"
                                        hide-details
                                        class="shrink mr-2 mt-0"
                                      ></v-checkbox>
                                      <v-text-field
                                        v-model="
                                          transference_manages.manage_reason_1_spouse
                                        "
                                        :disabled="
                                          !transference_manages.manage_reason_1
                                        "
                                        label="คู่สมรสชื่อ : ชื่อ-นามสกุล"
                                      ></v-text-field>
                                      <v-text-field
                                        v-model="
                                          transference_manages.manage_reason_1_occupation
                                        "
                                        :disabled="
                                          !transference_manages.manage_reason_1
                                        "
                                        label="คู่สมรสประกอบอาชีพ : "
                                      ></v-text-field>
                                      <v-text-field
                                        v-model="
                                          transference_manages.manage_reason_1_location
                                        "
                                        :disabled="
                                          !transference_manages.manage_reason_1
                                        "
                                        label="สถานที่ประกอบอาชีพของคู่สมรส : "
                                      ></v-text-field>
                                      <v-autocomplete
                                        v-model="
                                          transference_manages.manage_reason_1_domicile
                                        "
                                        :items="provices_sh"
                                        item-text="province_name"
                                        item-value="province_ID"
                                        :disabled="
                                          !transference_manages.manage_reason_1
                                        "
                                        label="ภูมิลำเนาของคู่สมรส จังหวัด : "
                                      ></v-autocomplete>
                                    </v-row>
                                    <v-row align="center">
                                      <v-card-title>
                                        <div
                                          class="font-weight-light v-size--x-large"
                                        >
                                          ย้ายเพื่อดูแลบิดา มารดา :
                                        </div>
                                      </v-card-title>

                                      <v-checkbox
                                        v-model="
                                          transference_manages.manage_reason_2
                                        "
                                        value="1"
                                        hide-details
                                        class="shrink mr-2 mt-0"
                                      ></v-checkbox>
                                      <v-autocomplete
                                        :items="manage_reason_2_years"
                                        item-text="text"
                                        item-value="value"
                                        v-model="
                                          transference_manages.manage_reason_2_fyear
                                        "
                                        :disabled="
                                          !transference_manages.manage_reason_2
                                        "
                                        label="อายุของบิดาปี"
                                      ></v-autocomplete>
                                      <v-autocomplete
                                        :items="manage_reason_2_years"
                                        item-text="text"
                                        item-value="value"
                                        v-model="
                                          transference_manages.manage_reason_2_myear
                                        "
                                        :disabled="
                                          !transference_manages.manage_reason_2
                                        "
                                        label="อายุของมารดาปี"
                                      ></v-autocomplete>
                                      <v-autocomplete
                                        v-model="
                                          transference_manages.manage_reason_2_domicile
                                        "
                                        :items="provices_sh"
                                        item-text="province_name"
                                        item-value="province_ID"
                                        :disabled="
                                          !transference_manages.manage_reason_2
                                        "
                                        label="ภูมิลำเนาของบิดา มารดา จังหวัด : "
                                      ></v-autocomplete>
                                    </v-row>
                                    <v-row align="center">
                                      <v-card-title>
                                        <div
                                          class="font-weight-light v-size--x-large"
                                        >
                                          ย้ายกลับภูมิลำเนาของข้าพเจ้า จังหวัด :
                                        </div>
                                      </v-card-title>

                                      <v-checkbox
                                        v-model="
                                          transference_manages.manage_reason_3
                                        "
                                        value="1"
                                        hide-details
                                        class="shrink mr-2 mt-0"
                                      ></v-checkbox>
                                      <v-autocomplete
                                        v-model="
                                          transference_manages.manage_reason_3_domicile
                                        "
                                        :items="provices_sh"
                                        item-text="province_name"
                                        item-value="province_ID"
                                        :disabled="
                                          !transference_manages.manage_reason_3
                                        "
                                        label="ภูมิลำเนาของข้าพเจ้า จังหวัด : "
                                      ></v-autocomplete>
                                    </v-row>
                                    <v-row align="center">
                                      <v-card-title>
                                        <div
                                          class="font-weight-light v-size--x-large"
                                        >
                                          เหตุผลอื่น :
                                        </div>
                                      </v-card-title>
                                      <v-checkbox
                                        v-model="
                                          transference_manages.manage_reason_4
                                        "
                                        value="1"
                                        hide-details
                                        class="shrink mr-2 mt-0"
                                      ></v-checkbox>
                                      <v-textarea
                                        v-model="
                                          transference_manages.manage_reason_4_detail
                                        "
                                        :disabled="
                                          !transference_manages.manage_reason_4
                                        "
                                        label="(ระบุ) : "
                                      ></v-textarea>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-card>

                            <v-card
                              class="pa-2 ma-2"
                              v-if="
                                transference_manages.manage_case_move ===
                                  'special'
                              "
                            >
                              <v-row>
                                <v-col cols="12" sm="12" class="pa-5">
                                  <v-card-title>
                                    <div
                                      class="font-weight-light v-size--x-large"
                                      color="pink darken-2"
                                    >
                                      <v-icon large left
                                        >mdi-account-details</v-icon
                                      >
                                      เหตุผลการขอย้าย กรณีพิเศษ
                                    </div>                                  
                                  </v-card-title>

                                  <v-card class="pa-2">
                                    <v-card-text>
                                      <v-row align="center">
                                        <v-card-title>
                                          <div
                                            class="font-weight-light v-size--x-large"
                                          >
                                            <v-icon></v-icon>
                                            ย้ายเพื่อติดตามคู่สมรส
                                            เจ็บป่วยร้ายแรง
                                          </div>
                                        </v-card-title>

                                        <v-checkbox
                                          v-model="
                                            transference_manages.manage_reason_1_sp
                                          "
                                          value="1"
                                          hide-details
                                          class="shrink mr-2 mt-0"
                                        ></v-checkbox>
                                        <v-text-field
                                          v-model="
                                            transference_manages.manage_reason_1_sp_case
                                          "
                                          :disabled="
                                            !transference_manages.manage_reason_1
                                          "
                                          label="เนื่องจาก"
                                        ></v-text-field>
                                      </v-row>
                                      <v-row align="center">
                                        <v-card-title>
                                          <div
                                            class="font-weight-light v-size--x-large"
                                          >
                                            ย้ายเพื่อดูแลบิดามารดา คู่สมรส
                                            หรือบุตร
                                            ซึ่งเจ็บป่วยร้ายแรงหรือทุพพลภาพ:
                                          </div>
                                        </v-card-title>

                                        <v-checkbox
                                          v-model="
                                            transference_manages.manage_reason_2_sp
                                          "
                                          value="1"
                                          hide-details
                                          class="shrink mr-2 mt-0"
                                        ></v-checkbox>
                                        <v-text-field
                                          v-model="
                                            transference_manages.manage_reason_2_sp_case
                                          "
                                          :disabled="
                                            !transference_manages.manage_reason_2
                                          "
                                          label="เนื่องจาก"
                                        ></v-text-field>
                                      </v-row>
                                      <v-row align="center">
                                        <v-card-title>
                                          <div
                                            class="font-weight-light v-size--x-large"
                                          >
                                            ถูกคุกคามต่อชีวิต :
                                          </div>
                                        </v-card-title>

                                        <v-checkbox
                                          v-model="
                                            transference_manages.manage_reason_3_sp
                                          "
                                          value="1"
                                          hide-details
                                          class="shrink mr-2 mt-0"
                                        ></v-checkbox>
                                        <v-text-field
                                          v-model="
                                            transference_manages.manage_reason_3_case
                                          "
                                          :disabled="
                                            !transference_manages.manage_reason_3
                                          "
                                          label="รายละเอียด: "
                                        ></v-text-field>
                                      </v-row>
                                      <v-row align="center">
                                        <v-textarea
                                          v-model="
                                            transference_manages.manage_case_detail
                                          "
                                          outlined
                                          prepend-icon="mdi-office-building-marker"
                                          rows="2"
                                          label="พิเศษ กรณีอื่น ๆ :"
                                          :disabled="
                                            transference_manages.manage_case_move ===
                                              'normal'
                                          "
                                        ></v-textarea>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 15))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=13)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content> -->

              <v-stepper-step :complete="steps > 15" step="15">
                

                <div>
                  ข้าพเจ้ามีความประสงค์ขอย้ายไปดำรงตำแหน่งที่
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>


              </v-stepper-step>
              <v-stepper-content step="15">
                <v-card class="pa-2 ma-2">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-card class="px-5 py-3">
                        <v-btn
                          x-large
                          color="success"
                          dark
                          rounded
                          @click.stop="
                            addtransference_manage_locationdialogsubmit()
                          "
                        >
                          <v-icon>mdi-selection-multiple-marker</v-icon>
                          <span> เลือกหน่วยงานแห่งใหม่</span>
                        </v-btn>

                        <v-data-table
                          :headers="header_trans"
                          :items="transference_manage_locations"
                        >
                          <template v-slot:item.action="{ item }">
                            <v-icon
                              color="red"
                              @click.stop="
                                transference_manage_locationDelete(
                                  item.manage_location_id_tfl
                                )
                              "
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>

                        <v-card class="pa-2 ma-2">
                          <v-card-title>
                            <div class="font-weight-light v-size--x-large">
                              <v-icon large left
                                >mdi-office-building-marker</v-icon
                              >
                              ถ้าไม่ได้ตามที่ระบุ
                            </div>
                          </v-card-title>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-row>
                                <v-alert
                                  class="mx-auto justify-center pa-2 ma-2"
                                  border="bottom"
                                  colored-border
                                  type="warning"
                                  elevation="2"
                                >
                                  <v-radio-group
                                    row
                                    v-model="
                                      transference_manages.manage_move_if
                                    "
                                  >
                                    <v-radio value="suspend">
                                      <template v-slot:label>
                                        <div>
                                          <strong
                                            class="primary--text"
                                            v-on:click="clear_gov_service()"
                                            >ขอระงับการย้าย</strong
                                          >
                                        </div>
                                      </template>
                                    </v-radio>
                                    <v-radio value="another">
                                      <template v-slot:label>
                                        <div>
                                          <strong class="warning--text"
                                            >หน่วยงานการศึกษาใดก็ได้ใน</strong
                                          >
                                        </div>
                                      </template>
                                    </v-radio>
                                  </v-radio-group>
                                </v-alert>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-card class="pa-2 ma-2">
                                <v-row>
                                  <v-col cols="12" sm="12">
                                    <v-autocomplete
                                      v-model="
                                        transference_manages.manage_move_if_detail
                                      "
                                      :items="provices_sh"
                                      item-text="province_name"
                                      item-value="province_ID"
                                      outlined
                                      prepend-icon="mdi-office-building-marker"
                                      rows="2"
                                      label="สอจ. :"
                                      :disabled="
                                        transference_manages.manage_move_if ===
                                          'suspend'
                                      "
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <h3>
                        ขอย้ายสับเปลี่ยนกับ :
                        {{ personnel_temporarys.title_s }}
                        {{ personnel_temporarys.frist_name }}
                        {{ personnel_temporarys.last_name }}
                      </h3>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="transference_manages.manage_switch_position"
                        :counter="13"
                        outlined
                        label="บัตรประชาชน"
                        prepend-icon="mdi-account-details"
                        request
                        v-on:keyup.enter="OnEnter()"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3">
                      <v-text-field
                        :value="personnel_temporarys.position_name"
                        :counter="100"
                        outlined
                        label="ตำแหน่ง :"
                        prepend-icon="mdi-account-star"
                        request
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        :value="personnel_temporarys.rang_name"
                        :counter="100"
                        outlined
                        label="วิทยฐานะ :"
                        prepend-icon="mdi-account-details"
                        request
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        :value="personnel_temporarys.id_position"
                        :counter="100"
                        outlined
                        label="เลขที่ตำแหน่ง :"
                        prepend-icon="mdi-account-details"
                        request
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        :value="personnel_temporarys.salary_s"
                        :counter="100"
                        outlined
                        label="เงินเดือน :"
                        prepend-icon="mdi-account-details"
                        request
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        :value="personnel_temporarys.college_name"
                        :counter="100"
                        outlined
                        label="สังกัด :"
                        prepend-icon="mdi-flag-letiant"
                        request
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <span
                        >*
                        กรณีย้ายสับเปลี่ยนให้กรอกหมายเลขบัตรประจำตัวประชาชนของผู้ที่ท่านประสงค์สับเปลี่ยน
                        และกดปุ่ม Enter เพื่อดำเนินการค้นหา
                        หากไม่ปรากฏแสดงว่าไม่มีบุคคลดังกล่าวในระบบ
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 15))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=13)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 16" step="16">
                

                <div>
                  หลักฐานประกอบการพิจารณา
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>

              </v-stepper-step>
              <v-stepper-content step="16">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-row class="pa-4 mt-0">
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="
                              transference_manages.manage_evidence_info_1
                            "
                            value="1"
                            label="สำเนา กพ.7/ก.ค.ศ.16"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="
                              transference_manages.manage_evidence_info_2
                            "
                            value="1"
                            label="สำเนาทะเบียนบ้านของตน"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="
                              transference_manages.manage_evidence_info_3
                            "
                            value="1"
                            label="สำเนาใบสำคัญการสมรส"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="
                              transference_manages.manage_evidence_info_4
                            "
                            value="1"
                            label="ใบรับรองแพทย์"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="
                              transference_manages.manage_evidence_info_5
                            "
                            value="1"
                            label="บันทึกประจำวัน ตำรวจ/ฝ่ายปกครอง"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="
                              transference_manages.manage_evidence_info_6
                            "
                            value="1"
                            label="บันทึกข้อตกลงของผู้ขอย้ายสับเปลี่ยนทุกคน"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="
                              transference_manages.manage_evidence_info_7
                            "
                            value="1"
                            label="วิสัยทัศน์ในการบริหารจัดการศึกษา"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-row align="center">
                            <v-card-title>
                              <div class="font-weight-light v-size--x-large">
                                อื่นๆ :
                              </div>
                            </v-card-title>
                            <v-checkbox
                              v-model="
                                transference_manages.manage_evidence_info_8
                              "
                              value="1"
                              hide-details
                              class="shrink mr-2 mt-0"
                            ></v-checkbox>
                            <v-textarea
                              outlined
                              v-model="
                                transference_manages.manage_evidence_info_8_detail
                              "
                              :disabled="
                                !transference_manages.manage_evidence_info_8
                              "
                              label="(ระบุ) : "
                            ></v-textarea>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 16))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=14)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 17" step="17">
                
                <div>
                  เหตุผลการขอย้าย อื่นๆ เพิ่มเติม
                  <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
                </div>

              </v-stepper-step>
              <v-stepper-content step="17">
                <v-card>
                  <v-textarea
                    outlined
                    v-model="transference_manages.manage_reason_detail"
                    label="(ระบุ) : "
                  ></v-textarea>
                </v-card>
                <v-btn
                  @click="updatetransference_manageSubmit((step = 17))"
                  color="primary"
                >
                  บันทึก
                </v-btn>
                <v-btn text @click="canceltransference(step=15)">
                  ยกเลิก
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="steps > 18" step="18">
                แบบแสดงความประสงค์ขอย้าย
              </v-stepper-step>
              <v-stepper-content step="18">
                <v-card>
                  <v-btn
                    x-large
                    color="info"
                    dark
                    class="ma-2"
                    :href="'#/user/print_info_manage_d/' + manage_id_ref"
                    target="_blank"
                  >
                    <v-icon>mdi-printer</v-icon>
                    <span>พิมพ์แบบแสดงความประสงค์ขอย้าย</span>
                  </v-btn>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </base-material-card>

        <!--addtransference_manage_locationdialog  -->
        <v-layout>
          <v-dialog
            v-model="addtransference_manage_locationdialog"
            persistent
            max-width="60%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เลือกวิทยาลัย"
                class="px-5 py-3"
              >
                รหัสรายการอ้างอิง {{ manage_id_ref }}
              </base-material-card>
              <v-card-text>
                <v-form
                  ref="addtransference_manage_locationform"
                  lazy-validation
                >
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex md2>
                        <v-select
                          v-model="
                            addtransference_manage_location.manage_location_sequence_n
                          "
                          :items="order_need"
                          outlined
                          label="ลำดับที่ :"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex md10>
                        <v-autocomplete
                          v-model="
                            addtransference_manage_location.manage_location_college_code
                          "
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          outlined
                          label="สังกัด :"
                          prepend-icon="mdi-flag-letiant"
                          request
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  large
                  @click.stop="addtransference_manage_locationdialog = false"
                  ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="success"
                  @click.stop="addtransference_manage_locationSubmit()"
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model deleteuserdialog -->
        <v-layout>
          <v-dialog
            v-model="delettransference_manage_locationdialog"
            persistent
            max-width="40%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบรายการ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>

              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="deletetransference_manage_locationform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบ
                            <span>
                              {{
                                edittransference_manage_location.college_name
                              }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="delettransference_manage_locationdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deletetransference_manage_locationSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model confirm -->
        <v-layout>
          <v-dialog v-model="confirm_dialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="warning"
                icon="mdi-content-save"
                title="ยืนยันรายการ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>

              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="confirmtransference_manageform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-alert
                          outlined
                          type="warning"
                          prominent
                          border="left"
                        >
                          <h2>
                            เตือน
                          </h2>
                          <h3>
                            หากกดปุ่มบันทึกท่านไม่สามารถ แก้ไขข้อมูลใดๆ ได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึก
                          </h3>
                        </v-alert>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large rounded @click.stop="confirm_dialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="warning"
                  @click.stop="confirmtransference_manageSubmit()"
                  dark
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <v-dialog v-model="previewReport" width="80%">
          <v-card>
            <iframe
              style="width:100%; height: 600px;"
              :src="'#/user/print_info_manage_d_preview/?manage_id_ref=' + manage_id_ref"
            ></iframe>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "HrvecTransferencePersonnel",

  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      localTime: "",
      isHidden: false,
      timestamp: "",
      colleges: [],
      personnel_search: {},
      show_personnel: [],
      transference_manage_locations: [],
      search: "",
      addtransference_manage_locationdialog: false,
      delettransference_manage_locationdialog: false,
      Search_Personneldialog: false,
      confirm_dialog: false,
      user: {},
      transference_manage: [],
      transference_manages: [],

      addtransference_manage: {
        manage_on_study_leave: "no_leave",
        manage_disciplinary: "no_discip",
        manage_license: "no_license",
        manage_case_move: "normal",
        manage_move_if: "suspend",
        manage_switch_position: "-",
        manage_service_status: "not_service"
      },

      addtransference_manage_location: {},
      edittransference_manage_location: [],
      provinces: [],
      provices_sh: [],
      prefectures: [],
      personnel_educations: [],
      personnel_education_check: [],
      district: [],
      district_post_s: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      order_need: [1, 2, 3],
      header_trans: [
        { text: "ลำดับ", align: "center", value: "manage_location_sequence_n" },
        {
          text: "รหัสอ้างอิง",
          align: "center",
          value: "manage_location_id_ref"
        },
        { text: "ตำแหน่ง", align: "center", value: "manage_location_position" },
        { text: "วิทยาลัย", align: "center", value: "college_name" },
        { text: "ยกเลิก", align: "center", value: "action" }
      ],
      header_work: [
        { text: "ลำดับ", align: "center", value: "index" },
        { text: "วันเดือนปี", align: "center", value: "date_begin" },
        { text: "ตำแหน่ง/ระดับ", align: "center", value: "position_s" },
        { text: "สังกัด", align: "center", value: "college_code" },
        { text: "หมายเหตุ", align: "center", value: "action" }
      ],

      isEditing: null,
      show1: false,
      pagination: {},
      personnel_marriages: [],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      personnel_temporarys: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal_1: false,
      modal_2: false,
      modal_3: false,
      modal_4: false,
      menu2: false,
      periods: [],
      period_enable: "1",
      personnel_work_history: [],
      personnel_work_history_check: [],
      transference_manage_location_update: {},
      options: [{ value: "th", label: "Thai" }],
      manage_reason_2_years: [
        { text: "เสียชีวิต", value: "เสียชีวิต" },
        { text: "30", value: "30" },
        { text: "31", value: "31" },
        { text: "32", value: "32" },
        { text: "33", value: "33" },
        { text: "34", value: "34" },
        { text: "35", value: "35" },
        { text: "36", value: "36" },
        { text: "37", value: "37" },
        { text: "38", value: "38" },
        { text: "39", value: "39" },
        { text: "40", value: "40" },
        { text: "41", value: "41" },
        { text: "42", value: "42" },
        { text: "43", value: "43" },
        { text: "44", value: "44" },
        { text: "45", value: "45" },
        { text: "46", value: "46" },
        { text: "47", value: "47" },
        { text: "48", value: "48" },
        { text: "49", value: "49" },
        { text: "50", value: "50" },
        { text: "51", value: "51" },
        { text: "52", value: "52" },
        { text: "53", value: "53" },
        { text: "54", value: "54" },
        { text: "55", value: "55" },
        { text: "56", value: "56" },
        { text: "57", value: "57" },
        { text: "58", value: "58" },
        { text: "59", value: "59" },
        { text: "60", value: "60" },
        { text: "61", value: "61" },
        { text: "62", value: "62" },
        { text: "63", value: "63" },
        { text: "64", value: "64" },
        { text: "65", value: "65" },
        { text: "66", value: "66" },
        { text: "67", value: "67" },
        { text: "68", value: "68" },
        { text: "69", value: "69" },
        { text: "70", value: "70" },
        { text: "71", value: "71" },
        { text: "72", value: "72" },
        { text: "73", value: "73" },
        { text: "74", value: "74" },
        { text: "75", value: "75" },
        { text: "76", value: "76" },
        { text: "77", value: "77" },
        { text: "78", value: "78" },
        { text: "79", value: "79" },
        { text: "80", value: "80" },
        { text: "81", value: "81" },
        { text: "82", value: "82" },
        { text: "83", value: "83" },
        { text: "84", value: "84" },
        { text: "85", value: "85" },
        { text: "86", value: "86" },
        { text: "87", value: "87" },
        { text: "88", value: "88" },
        { text: "89", value: "89" },
        { text: "90", value: "90" },
        { text: "91", value: "91" },
        { text: "92", value: "92" },
        { text: "93", value: "93" },
        { text: "94", value: "94" },
        { text: "95", value: "95" },
        { text: "96", value: "96" },
        { text: "97", value: "97" },
        { text: "98", value: "98" },
        { text: "99", value: "99" },
        { text: "100", value: "100" },
        { text: "101", value: "101" },
        { text: "102", value: "102" },
        { text: "103", value: "103" },
        { text: "104", value: "104" },
        { text: "105", value: "105" },
        { text: "106", value: "106" },
        { text: "107", value: "107" },
        { text: "108", value: "108" },
        { text: "109", value: "109" },
        { text: "110", value: "110" },
        { text: "111", value: "111" },
        { text: "112", value: "112" },
        { text: "113", value: "113" },
        { text: "114", value: "114" },
        { text: "115", value: "115" },
        { text: "116", value: "116" },
        { text: "117", value: "117" },
        { text: "118", value: "118" },
        { text: "119", value: "119" },
        { text: "120", value: "120" },
        { text: "121", value: "121" },
        { text: "122", value: "122" },
        { text: "123", value: "123" },
        { text: "124", value: "124" },
        { text: "125", value: "125" },
        { text: "126", value: "126" },
        { text: "127", value: "127" },
        { text: "128", value: "128" },
        { text: "129", value: "129" },
        { text: "130", value: "130" },
        { text: "131", value: "131" },
        { text: "132", value: "132" },
        { text: "133", value: "133" },
        { text: "134", value: "134" },
        { text: "135", value: "135" },
        { text: "136", value: "136" },
        { text: "137", value: "137" }
      ],
      personnel_temporarys: [],
      steps: 1,
      previewReport:false,
    };
  },

  async mounted() {
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let result_provice;
    result_provice = await this.$http.post("province_sh.php", {
      ApiKey: this.ApiKey
    });
    this.provices_sh = result_provice.data;

    let result;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;

    let result_user;
    result_user = await this.$http.post("personnel_temporary.php", {
      ApiKey: this.ApiKey,
      id_card: userSession.id_card
    });
    this.user = result_user.data;

    await this.periodQuery();
    await this.transference_manageAll();
    await this.transference_manage_locationQueryAll();
    await this.personnel_educationsQueryAll();
    await this.personnel_educations_checkQueryAll();
    await this.personnel_work_historyQueryAll();
    await this.personnel_work_historyCheck();
    await this.personnel_temporarysQueryAll();

    this.showLocaleTime();
  },

  methods: {
    async previewReportShow() {
      this.previewReport = true;
    },

    clearCasemove() {
      this.$refs.updateCasedetail.clearable();
    },
    showLocaleTime() {
      var time = this;
      setInterval(function() {
        time.localTime = new Date().toLocaleTimeString();
      }, 1000);
    },
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "OK",
        period_type: this.user_status_type
      });
      this.periods = result_period.data;

      if (
        this.periods.period_enable === "1" &&
        this.periods.period_type === "manage"
      ) {
      } else {
        this.$router.push("/UserProfile");
      }

     /*  if (this.get_gov_Age_year >= 1) {
      } else {
        this.$router.push("/UserProfile");
        let texts =
          "ตามนัยหนังสือสำนักงาน ก.ค.ศ. ที่ ศธ 0206.4/ว 7 ลงวันที่ 12                พฤษภาคม 2564 หลักเกณฑ์และวิธีการย้ายผู้บริหารสถานศึกษา สังกัดกระทรวงศึกษาธิการ ผู้ขอย้ายดำรงตำแหน่งในสถานศึกษาปัจจุบันไม่น้อยกว่า 12 เดือน โดยให้นับระยะเวลาการดำรงตำแหน่งถึงวันที่ 30 กันยายน ของปีที่ยื่นคำร้องขอย้ายประจำปี";
        Swal.fire({
          icon: "error",
          title: texts,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/UserProfile");
          }
        });
      } */
    },
    async personnel_work_historyQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_work_history.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_work_history = result.data;
    },

    async personnel_work_historyCheck() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_work_history.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card,
          count_check: "Ok"
        })
        .finally(() => (this.loading = false));
      this.personnel_work_history_check = result.data;
      /*      */
    },

    async personnel_educationsQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_educations = result.data;
    },

    async personnel_educations_checkQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education.check.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_education_check = result.data;
    },

    async OnEnter() {
      let data;
      if (this.addtransference_manage.manage_switch_position) {
        data = this.addtransference_manage.manage_switch_position;
      }
      if (this.transference_manages.manage_switch_position) {
        data = this.transference_manages.manage_switch_position;
      }

      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: data
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async transference_manageAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_id_ref: this.manage_id_ref
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;

      let stepsep = this.transference_manages.manage_status_save;
      
      if (stepsep == "Save") {
        this.steps = 18;
      } else if (stepsep >= 1) {
        this.steps = parseInt(this.transference_manages.manage_status_save) + 1;
      } else {
        this.steps = 1;
      }
    },

    async personnel_temporarysQueryAll() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_manages.manage_switch_position
      });
      this.personnel_temporarys = result.data;
    },

    async transference_manage_locationQueryAll() {
      let result = await this.$http.post("transference_manage_location.php", {
        ApiKey: this.ApiKey,
        manage_location_id_ref: this.manage_id_ref
      });
      this.transference_manage_locations = result.data;
    },

    //First >> Insert transference Location
    async addtransference_manage_locationdialogsubmit() {
      this.addtransference_manage_location = {};
      this.addtransference_manage_locationdialog = true;
    },

    async Search_Personnel() {
      this.personnel_select = {};
      this.Search_Personneldialog = true;
    },

    async addtransference_manage_locationSubmit() {
      if (this.$refs.addtransference_manage_locationform.validate()) {
        this.addtransference_manage_location.ApiKey = this.ApiKey;
        this.addtransference_manage_location.manage_location_id_ref = this.manage_id_ref;
        this.addtransference_manage_location.manage_location_id_card = this.user.id_card;
        this.addtransference_manage_location.manage_location_position = this.user.position_name;

        let result = await this.$http.post(
          "transference_manage_location.insert.php",
          this.addtransference_manage_location
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manage_locationQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text =
            "บันทึกข้อมูลผิดพลาด รายการลำดับ หรือ ชื่อหน่วยงานแหน่งใหม่ซ้ำ";
          this.snackbar.show = true;
        }
        this.addtransference_manage_locationdialog = false;
      }
    },

    //Confirm data

    async confirmsSubmit() {
      this.confirm_dialog = true;
    },

    //Delete transference Location
    async transference_manage_locationDelete(manage_location_id_tfl) {
      let result = await this.$http.post("transference_manage_location.php", {
        ApiKey: this.ApiKey,
        manage_location_id_tfl: manage_location_id_tfl
      });
      this.edittransference_manage_location = result.data;
      this.delettransference_manage_locationdialog = true;
    },
    async deletetransference_manage_locationSubmit() {
      if (this.$refs.deletetransference_manage_locationform.validate()) {
        this.edittransference_manage_location.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "transference_manage_location.delete.php",
          this.edittransference_manage_location
        );
        if (result.data.status == true) {
          this.transference_manage_location = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manage_locationQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.delettransference_manage_locationdialog = false;
      }
    },

    // Add transference_manage
    async addtransference_manageSubmit(steps) {
      Swal.fire({
        title: "ประสงค์ทำรายการยืนย้าย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
         
            this.addtransference_manage.ApiKey = this.ApiKey;
            this.addtransference_manage.manage_id_ref = this.manage_id_ref;
            this.addtransference_manage.manage_time_s = this.periods.period_times;
            this.addtransference_manage.manage_year_s = this.periods.period_year;
            this.addtransference_manage.manage_year_age = String(this.get_Age);

            this.addtransference_manage.manage_year_budget = this.periods.period_year;
            this.addtransference_manage.manage_id_card = this.user.id_card;
            this.addtransference_manage.manage_date_time = this.date_today;
            this.addtransference_manage.manage_age_app_time = String(
              this.get_Appoint_Age
            );
            this.addtransference_manage.manage_age_time = String(
              this.get_gov_Age
            );
            this.addtransference_manage.manage_college_code_now = this.user.college_code;
            this.addtransference_manage.manage_case_move = "quality";
            this.addtransference_manage.manage_status_save = steps;

            let result = await this.$http.post(
              "transference_manage.insert.php",
              this.addtransference_manage
            );

            if (result.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              this.transference_manageAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }        
      });
    },

    async updatetransference_manageSubmit(step) {
      this.transference_manages.ApiKey = this.ApiKey;
      this.transference_manages.manage_age_app_time = String(
        this.get_Appoint_Age
      );
      this.transference_manages.manage_year_age = String(this.get_Age);
      this.transference_manages.manage_age_time = String(this.get_gov_Age);
      this.transference_manages.manage_status_save = step;
      this.transference_manages.manage_age_app_time = String(
        this.get_Appoint_Age
      );
      if(this.transference_manages.manage_reason_1!=1){
        this.transference_manages.manage_reason_1=0
      }
      if(this.transference_manages.manage_reason_2!=1){
        this.transference_manages.manage_reason_2=0
      }
      if(this.transference_manages.manage_reason_3!=1){
        this.transference_manages.manage_reason_3=0
      }
      if(this.transference_manages.manage_reason_4!=1){
        this.transference_manages.manage_reason_4=0
      }
      if(this.transference_manages.manage_reason_1_sp!=1){
        this.transference_manages.manage_reason_1_sp=0
      }
      if(this.transference_manages.manage_reason_2_sp!=1){
        this.transference_manages.manage_reason_2_sp=0
      }
      if(this.transference_manages.manage_reason_3_sp!=1){
        this.transference_manages.manage_reason_3_sp=0
      }

      if (step == 17) {
        this.transference_manages.manage_status_save = "Save";
        this.transference_manage_location_update.ApiKey = this.ApiKey;
        this.transference_manage_location_update.manage_location_id_ref = this.transference_manages.manage_id_ref;
        this.transference_manage_location_update.manage_location_status_confirm =
          "Save";

        Swal.fire({
          title:
            "ขั้นตอนนี้เป็นขั้นตอนสุดท้าย เมื่อท่านกดยืนยันจะไม่สามารถยกเลิกได้แล้วกรุณาตรวจสอบให้ถูกต้อง",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก"
        }).then(async result => {
          if (result.isConfirmed) {
            
            let result = await this.$http.post(
              "transference_manage.update.php",
              this.transference_manages
            );
            let result_up = await this.$http.post(
              "transference_manage_location.update.php",
              this.transference_manage_location_update
            );
            

            if (result.data.status == true || result_up.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              this.transference_manageAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        });
      } else {
        
        let result = await this.$http.post(
          "transference_manage.update.php",
          this.transference_manages
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manageAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },

    async canceltransference(step) {
      if(step==2){
        this.transference_manages.ApiKey = this.ApiKey;
        this.transference_manage_location_update.ApiKey = this.ApiKey;
        this.transference_manage_location_update.manage_location_id_ref = this.transference_manages.manage_id_ref;

        Swal.fire({
          title:
            "ต้องการยกเลิกรายการยืนย้ายครั้งนี้",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก"
        }).then(async result => {
          if (result.isConfirmed) {
    
            let result = await this.$http.post(
              "transference_manage.delete.php",
              this.transference_manages
            );
           


            let result_up = await this.$http.post(
              "transference_manage_location.delete.cancel.php",
              this.transference_manage_location_update
            );
            

            if (result.data.status == true || result_up.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              this.transference_manageAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        });
      }else{
        this.transference_manages.ApiKey = this.ApiKey;
      this.transference_manages.manage_age_app_time = String(
        this.get_Appoint_Age
      );
      this.transference_manages.manage_year_age = String(this.get_Age);
      this.transference_manages.manage_age_time = String(this.get_gov_Age);
      this.transference_manages.manage_status_save = step;
      this.transference_manages.manage_age_app_time = String(
        this.get_Appoint_Age)

        let result = await this.$http.post(
          "transference_manage.update.php",
          this.transference_manages
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manageAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },
    
  },
  computed: {
    timenow() {
      const d = new Date();
      let hour = d.getHours();
      let minutes = d.getMinutes();
      let seconds = d.getSeconds();
      let result = hour + ":" + minutes + ":" + seconds;
      return result;
    },

    user_status_type() {
      let user_status = this.user.user_status;
      let result;
      if (user_status == "teacher") {
        result = "teacher";
      } else if (user_status == "director") {
        result = "manage";
      } else if (user_status == "se_director") {
        result = "manage";
      }
      return result;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },

    manage_id_ref() {
      let manage_id_ref_cr;
      manage_id_ref_cr =
        "D" +
        this.periods.period_times +
        "" +
        parseInt(this.periods.period_year) +
        "" +
        this.user.id_card;
      return manage_id_ref_cr;
    },
    marital_status() {
      let marital_status = this.user.marital_status;
      let marital_result;
      if (marital_status == "single") {
        marital_result = "โสด";
      } else if (marital_status == "married") {
        marital_result = "สมรส";
      } else if (marital_status == "widow") {
        marital_result = "หม้าย";
      } else if (marital_status == "divorce") {
        marital_result = "หย่า";
      } else if (marital_status == "separate") {
        marital_result = "แยกกันอยู่";
      }
      return marital_result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },

    dmy_app_cal() {
      let month = this.user.appoin_month;
      let day = this.user.appoin_day;
      let year = this.user.appoin_year - 543;
      let result = month + "/" + day + "/" + year;
      return result;
    },

    date_appoin_ch() {
      let monthNames = [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ];
      let day = this.user.appoin_day;
      let month = monthNames[parseInt(this.user.appoin_month)];
      let year = this.user.appoin_year;
      let years = parseInt(year);
      let today = day + " " + month + " " + years;
      return today;
    },

    cal_age_gov() {
      const today = new Date();
      const birthDate = new Date(this.dmy_app_cal);
      const yearsDifference = today.getFullYear() - birthDate.getFullYear();
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        return yearsDifference - 1;
      }
      return yearsDifference;
    },
    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    date_month_cal() {
      let dd = "15";
      let mm = "7";
      let yyyy = parseInt(this.periods.period_year)-543;
      let date = yyyy + "-" + mm + "-" + dd;
      return date;
    },

    get_Age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        parseInt(this.user.brith_year) -
          543 +
          "-" +
          this.user.brith_month +
          "-" +
          this.user.brith_day
      );
      let result;
      result = {
        years: 0,
        months: 0,
        days: 0,
        toString: function() {
          return (
            (this.years ? this.years + " ปี " : "") +
            (this.months ? this.months + " เดือน " : "") +
            (this.days ? this.days + " วัน" : "")
          );
        }
      };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },

    get_Appoint_Age() {
      let today = new Date(this.periods.period_cal_end);
      let DOB = new Date(this.user.date_app_now);

      let result;
      result = {
        years: 0,
        months: 0,
        days: 0,
        toString: function() {
          return (
            (this.years ? this.years + " ปี " : "") +
            (this.months ? this.months + " เดือน " : "") +
            (this.days ? this.days + " วัน" : "")
          );
        }
      };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },

    get_gov_Age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        parseInt(this.user.appoin_year) -
          543 +
          "-" +
          this.user.appoin_month +
          "-" +
          this.user.appoin_day
      );
      let result;
      result = {
        years: 0,
        months: 0,
        days: 0,
        toString: function() {
          return (
            (this.years ? this.years + " ปี " : "") +
            (this.months ? this.months + " เดือน " : "") +
            (this.days ? this.days + " วัน" : "")
          );
        }
      };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },

    get_gov_Age_year_service() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.addtransference_manage.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    },

    get_gov_Age_month_service() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.addtransference_manage.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = months;
      return age;
    },

    get_gov_Age_year() {
      let today = new Date(this.periods.period_cal_end);
      let DOB = new Date(this.user.date_app_now);

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    }
  }

  /* watch: {
    async date_transference(newVal, oldVal) {
      await this.transference_manageAll();
    }
  } */
};
</script>

<style lang="scss" scoped></style>
